import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
// Components
import Header from "../components/Header"
import Work from "../components/Work"
import About from "../components/about"
import Skills from "../components/skills"
// import Promotion from "../components/Promotion"
import Footer from "../components/Footer"

const IndexPage = () => (
  <Layout>
    <SEO title="Aman Agarwal" />
    <Header></Header>
    <Work></Work>
    <About></About>
    <Skills></Skills>
    {/* <Promotion></Promotion> */}
    <Footer></Footer>

    <script type="text/javascript" async="" src="https://www.google-analytics.com/analytics.js"></script>

  </Layout>
)

export default IndexPage
