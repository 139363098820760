import githubIcon from "./images/github.svg"
import se from "./images/noun-web-developer-1873915.svg";
import intern from "./images/noun-intern-40716.svg";
import linkedin from "./images/linkedin-in-brands.svg";
import medium from "./images/medium-m-brands.svg";
import aman from "./images/aman.png";
export default {
    //(Please Do Not Remove The comma(,) after every variable)
    //Change The Website Template

    //   Header Details ---------------------
    name: "Aman",
    headerTagline: [
        //Line 1 For Header
        "Mixing",
        "Technology",
        //Line 2 For Header
        "With Healthcare."
    ],
    //   Header Paragraph
    headerParagraph: "I am passionate about building smart devices to improve health standards.",

    //Contact Email
    contactEmail: "agarwalaman.student@gmail.com",

    // End Header Details -----------------------

    // Work Section ------------------------
    projects: [{
            title: "Career Matrix",
            para: "As a software intern at Kuehne-Nagel, I had developed an employee almanac that would annotate the career track of each employee and help the employees regarding the possible changes in their career.", // Add Your Service Type Here
            imageSrc: "https://images.unsplash.com/photo-1551836022-4c4c79ecde51?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2940&q=80",
            url: "",
        },
        {
            title: "VIT OS",
            para: "VIT operating system was a simulation of macOS on a web-based chrome browser. The project's main aim was to provide an interface similar to macOS for routine university work. The application was later coupled into an independent computer application.", // Add Your Service Type Here
            imageSrc: "https://images.unsplash.com/photo-1519241047957-be31d7379a5d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1470&q=80",
            url: "",
        },
        {
            title: "VIT Teacher's Review Portal",
            para: "This project is a tool by which a teacher can collect feedback from students regarding the curriculum and teaching methodology. The student's anonymity is maintained while reporting the input to the teacher.", // Add Your Service Type Here
            imageSrc: "https://images.unsplash.com/photo-1511629091441-ee46146481b6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
            url: "https://github.com/agarwalaman263/VIT-Teachers-Portal",
        },
        {
            title: "Password Manager",
            para: "Password Manager is a command-line-based tool using the file system to store encrypted passwords. The passwords are held after the encryption process using the AES encryption algorithm, and the keys are shared with the user.", // Add Your Service Type Here
            imageSrc: "https://images.unsplash.com/photo-1618044733300-9472054094ee?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1471&q=80",
            url: "https://github.com/agarwalaman263/Password-Manager",
        },
        {
            title: "Patient Monitoring System", //Project Title - Add Your Project Title Here
            para: "It is a cloud-based entity that can receive data from various medical devices and trigger alerts to medical staff and doctors. It uses NodeJS servers for high computation along with Spring Boot servers for data operations and PostgreSQL as a database. RabbitMQ was being used to connect the services to make the workflow asynchronous.", // Add Your Service Type Here
            imageSrc: "https://images.unsplash.com/photo-1530508943348-b8f606ea2bf2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
            url: "https://github.com/agarwalaman263/PMS",
        }, {
            title: "Walking Stick for visually impaired people",
            para: "It is an IoT-based project where a walking stick is enhanced with an obstacle avoidance algorithm and GPS sensors. The walking stick would give the user an experience that would grant him extra freedom to move and allow them to be traced if they are lost.", // Add Your Service Type Here
            imageSrc: "https://images.unsplash.com/photo-1593757949569-6306b0777da0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2952&q=80",
            url: "",
        }
    ],

    // End Work Section -----------------------

    // About Secton --------------
    aboutSection: [`I am a software engineer working to build healthcare solutions using IoT, Cloud Computing, and Data Sciences. I have completed my undergraduate education at Vellore Institute of Technology, Vellore, in Computer Science and Engineering.`,
        "I have interned at Kuehne + Nagel and later pursued my interest by interning at Philips Healthcare. After completing my internship, I continued to work at Philips as a Software Engineer.",
        "I firmly believe that extensive use of technology can easily extend the average human life span by enabling early diagnosis of chronic diseases and regularly tracing the body's condition.",
    ],
    aboutImage: aman,

    //   End About Section ---------------------

    // Skills Section ---------------

    //   Import Icons from the top and link it here

    skills: [{
            company: 'Philips India Limited',
            tenure: "Senior Software Engineer",
            img: se,
            para: "Contributed to the architecture and implementation of features deployed on medical devices present in hospital premises. The components were developed using Java and Angular with Kubernetes as the orchestration tool.",
        },
        // {
        //     company: 'Philips India Limited',
        //     tenure: "Software Engineer",
        //     img: se,
        //     para: "Implemented and deployed various services on cloud and IoT front for better communication and remote controlling of medical devices present in medical facilities. ",
        // },
        {
            company: 'Philips India Limited',
            tenure: "Software Intern",
            img: intern,
            para: "Explored the regulations of working in the healthcare domain and supported the team by checking the feasibility of different approaches which could lead to the betterment of the product.",
        },
        {
            company: 'Kuehne + Nagel',
            tenure: "Summer Intern",
            img: intern,
            para: "Architected an HR tool that would help the employees clarify the prerequisites of different designations and the probability of them getting promoted. The tool was built using PostgreSQL, Flask, and jQuery.",
        },

    ],

    // End Skills Section --------------------------

    //   Promotion Section --------------------------

    promotionHeading: "Work Experience",
    promotionPara: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    // End Promotion Section -----------------

    //   Contact Section --------------

    contactSubHeading: "Let's create your next experience together",
    social: [
        // Add Or Remove The Link Accordingly
        {
            img: githubIcon,
            url: "https://github.com/agarwalaman263"
        },
        {
            img: linkedin,
            url: "https://www.linkedin.com/in/agarwalaman263/",
        }, {
            img: medium,
            url: "https://developerslens.medium.com/"
        }
    ],

    // End Contact Section ---------------
}