/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

//   const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle}
      meta={[
        {
            "property": "description",
            "content": "A portfolio which summaries my Dev experience. "
        },
        {
            "property": "keywords",
            "content": "HTML,CSS,XML,JavaScript,Web,Front-end,Backend"
        },
        {
            "property": "author",
            "content": "John Doe"
        },
        {
            "property": "viewport",
            "content": "width=device-width, initial-scale=1.0"
        },
        {
            "property": "viewport",
            "content": "width=device-width,minimum-scale=1"
        },
        {
            "property": "distribution",
            "content": "Global"
        },
        {
            "property": "robots",
            "content": "index, follow"
        },
        {
            "property": "revisit-after",
            "content": "1 days"
        },
        {
            "property": "author",
            "content": "Aman Agarwal"
        },
        {
            "property": "rating",
            "content": "General"
        },
        {
            "property": "expires",
            "content": "Never"
        },
        {
            "property": "author",
            "content": "Aman Agarwal"
        },
        {
            "property": "description",
            "content": "Hello, I am Aman Agarwal. I am a Computer Science graduate from VIT University and a Full Stack Web Developer."
        },
        {
            "property": "description",
            "content": "Hello, I am Aman Agarwal. I am a Computer Science graduate from VIT University and a Full Stack Web Developer."
        },
        {
            "property": "description",
            "content": "Hello, I am Aman Agarwal. I am a Computer Science graduate from VIT University and a Full Stack Web Developer."
        },
        {
            "property": "keywords",
            "content": "Aman Agarwal, VIT, Philips, Coding, Website, Design, App, Indore, Vellore, JavaScript, Kuehne-Nagel, Angular, Python, React, student, computer, science"
        },
        {
            "property": "copyright",
            "content": "Aman Agarwal"
        },
        {
            "property": "rating",
            "content": "General"
        },
        {
            "property": "coverage",
            "content": "Worldwide"
        },
        {
            "property": "reply-to",
            "content": "agarwalaman.student@gmail.com"
        },
        {
            "property": "creator",
            "content": "Aman Agarwal"
        },
        {
            "property": "twitter:card",
            "content": "summary_large_image"
        },
        {
            "property": "twitter:creator",
            "content": "@cyclopaman"
        },
        {
            "property": "twitter:title",
            "content": "Aman Agarwal - Web Developer"
        },
        {
            "property": "twitter:description",
            "content": "Hello, I am Aman Agarwal. I am a Computer Science graduate from VIT University and a Full Stack Web Developer."
        },
        {
            "property": "twitter:creator",
            "content": "@cyclopaman"
        },
        {
            "property": "twitter:image",
            "content": "Aman Agarwal | Web Developer | Software Engineer"
        },
        {
            "property": "msapplication-TileColor",
            "content": "#ffffff"
        },
        {
            "property": "msapplication-TileImage",
            "content": "/assets/favicons/ms-icon-144x144.png"
        },
        {
            "property": "theme-color",
            "content": "#ffffff"
        },
        {
          "property": "og:description",
          "content": "Hello, I am Aman Agarwal"
        },{
          "property": "fb:admins",
          "content":"000000"
        },{
          "property":"og:title",
          "content": "Aman Agarwal"
        },{
          "property": "og:site_name",
          "content": "Aman Agarwal"
        }
    ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO