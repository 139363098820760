import React from "react"

const Card = ({ heading, paragraph, imgUrl, projectLink }) => {
  return (
    <div
      className="card"
      style={{
        backgroundImage:
          "linear-gradient(to bottom, rgba(245, 246, 252, 0), rgba(0, 0, 0, 0.2)),url(" +
          imgUrl +
          ")",
      }}
    >
         <h1 className="title-tape">{heading}</h1>
      <div className="content">
     
        <p className="text">{paragraph}</p>
        {
          !!projectLink && <a
          href={projectLink ? projectLink : "#"}
          target="_blank"
          rel="noopener noreferrer"
          className="btn"
        >
          Explore
        </a> 
        }
        
      </div>
    </div>
  )
}

export default Card
