import React from "react"
import Fade from "react-reveal/Fade"
import data from "../yourdata"
// import aman from "./../images/aman";

const About = () => {
  return (
    <div className="section" id="about">
      <div className="container">
        <div className="about-section">
          <div className="content">
            <Fade bottom cascade>
              <h1>About Me</h1>
            </Fade>
            <p>
              {
                data.aboutSection.map((e, f) => 
                  f+1 === data.aboutSection.length ?  <react-dom>
                  {e}
                </react-dom> : <react-dom>
                      {e}
                      <br></br>
                      <br></br>
                    </react-dom>
                )
              }
            </p>
          </div>
          <div className="image-wrapper">
            <img src={data.aboutImage} alt="about" width="334" height="501"></img>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
